<script>
    import { tick } from 'svelte';

    let displayText = "";
    let currentIndex = 0;
    let mode = "typing";
    let targetTexts = ["Neka vaši dokumenti budu pod kontrolom.", "Neka vaši dokumenti rade za vas!"];
    let currentTargetIndex = 0;



    let showCursor = true;

    $: if (mode === "completed") {
        showCursor = false;
    }

    const type = async () => {
        while (currentTargetIndex < targetTexts.length) {
            let targetText = targetTexts[currentTargetIndex];
            
            if (mode === "typing") {
                if (currentIndex < targetText.length) {
                    displayText += targetText[currentIndex];
                    currentIndex++;
                } else if (currentTargetIndex === 0) {
                    mode = "erasing";
                    await new Promise(r => setTimeout(r, 1000)); // Wait before erasing
                } else {
                    mode = "completed";
                }
            } else if (mode === "erasing") {
                if (displayText !== "Neka vaši dokumenti ") {
                    displayText = displayText.slice(0, -1);
                    currentIndex--;
                } else {
                    mode = "typing";
                    currentTargetIndex++;
                    currentIndex = "Neka vaši dokumenti ".length;
                }
            }
            
            await tick();
            await new Promise(r => setTimeout(r, 80)); // Control speed
        }
    };

    type();
</script>


<p id='textm'>{displayText}{#if showCursor}<span class="cursor"></span>{/if}</p>

<style>
    #textm {
        white-space: pre; /* Keeps spaces as typed */
    }

    .cursor {
        display: inline-block;
        width: 2px;
        height: 1em;
        background-color: black;
        animation: blink 0.5s infinite alternate;
    }

    @keyframes blink {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
</style>
